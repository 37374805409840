import React from 'react'
import PropTypes from 'prop-types'
import loaderLight from '@assets/images/loader-light.svg'
import Cookies from 'universal-cookie'
import { StoreToCartWrapper, StyledP, Title, Error, Input, Label, Asterisk, SubmitButton } from './styles'

const InputPageContent = props => {
  const cookies = new Cookies()
  const { deliveryZipCode, errorMessage, handleInputChange, handleSubmit, loading, storeCartId } = props

  return (
    <StoreToCartWrapper>
      <Title>Store Cart</Title>
      <StyledP>
        If you have recently visited a showroom and have created a store cart with one of our representatives please
        enter your cart information below.
      </StyledP>
      <StyledP>
        <Asterisk className="asterisk">*</Asterisk>
        <span>= Required field</span>
      </StyledP>
      <Label htmlFor="store-cart-id">
        <span className="asterisk">*</span>
        Store Cart Number
        <Input
          id="store-cart-id"
          name="store-cart-id"
          data-testid="id-input"
          type="text"
          value={storeCartId || ''}
          onChange={handleInputChange}
          required
          aria-required="true"
        />
      </Label>
      <Label htmlFor="zip-code">
        <span className="asterisk">*</span>
        Delivery Zip Code
        <Input
          id="zip-code"
          name="zip-code"
          data-testid="zip-input"
          type="text"
          value={deliveryZipCode || (deliveryZipCode === null && cookies.get('__Host-storecartZipcode')) || ''}
          onChange={handleInputChange}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              handleSubmit()
            }
          }}
          aria-required="true"
          required
        />
      </Label>
      {errorMessage && <Error>{errorMessage}</Error>}
      <SubmitButton data-testid="submit" type="button" onClick={handleSubmit}>
        {loading ? <img alt="loading" src={loaderLight} /> : <p>FIND STORE CART</p>}
      </SubmitButton>
    </StoreToCartWrapper>
  )
}

InputPageContent.propTypes = {
  deliveryZipCode: PropTypes.string,
  errorMessage: PropTypes.string,
  handleInputChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  storeCartId: PropTypes.string,
}

export default InputPageContent
