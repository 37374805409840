import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getURLParam } from '@helpers/general'
import { addStoreCartEvent, setupAnalytics } from '@helpers/google-tag-manager'
import { setShippingAddressInfo, setContactInfo, setStoreInfo } from '@helpers/checkout/shipping-section'
import { navigate } from 'gatsby'
import { getStoreCartItem } from '@services/checkout'
import { setStoreCartCreationData } from '@redux/modules/cart'
import { UNABLE_TO_FIND_STORE_CART, FILLOUT_REQUIRED_FIELDS } from './messages'
import InputPageContent from './input-page-content'

const StoreToCart = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [storeCartId, setStoreCartId] = useState('')
  const [deliveryZipCode, setDeliveryZipCode] = useState(null)

  const handleInputChange = ({ target }) => {
    if (target.name === 'store-cart-id') {
      setStoreCartId(target.value)
    }
    if (target.name === 'zip-code') {
      setDeliveryZipCode(target.value)
    }
  }

  const handleSubmit = async () => {
    const isEmail = storeCartId && storeCartId.includes('@')
    const valueType = isEmail ? 'email' : 'storeCartId'
    if (!storeCartId || !deliveryZipCode) {
      setErrorMessage(FILLOUT_REQUIRED_FIELDS)
    } else {
      setErrorMessage('')
      try {
        // async request for the storecart data from the backend
        setLoading(true)
        let response = await getStoreCartItem(valueType, {
          [valueType]: storeCartId,
          zipcode: deliveryZipCode,
        })
        // getStoreCartByEmail returns an object with an array of carts. We take the latest cart in the array
        if (response.storeCartArray) {
          response = response.storeCartArray[response.storeCartArray.length - 1]
        }
        // set the user shipping address info and contact info based on the storecart response
        const { contact, shippingAddress } = response
        if (contact && shippingAddress) {
          setShippingAddressInfo(shippingAddress)
          setStoreInfo(response)
          setContactInfo(contact)
        }
        // if the response lineitems are a valid array setup the cart and save the response locally
        const validateArray = ({ lineItems }) => Array.isArray(lineItems) && lineItems.length > 0
        if (response && validateArray(response)) {
          const storeCartCreationData = {
            id: storeCartId,
            deliveryZipCode,
          }
          dispatch(setStoreCartCreationData(storeCartCreationData))
          addStoreCartEvent(response)
          // TODO: navigation need to add code to track ga data
          navigate('/cart')
        } else {
          setErrorMessage('There was an issue with your storecart')
        }
      } catch {
        setLoading(false)
        setErrorMessage(UNABLE_TO_FIND_STORE_CART)
      }
    }
  }
  useEffect(() => {
    const cartNumberFromUrl = getURLParam('storeCartNo')
    setStoreCartId(cartNumberFromUrl)
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'optimize.activate' })
    }
    setupAnalytics({
      pageData: { type: 'store-cart-input-page', title: 'Store Cart Input Page', path: '/store-cart-input-page' },
    })
  }, [])
  return (
    <InputPageContent
      deliveryZipCode={deliveryZipCode}
      errorMessage={errorMessage}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      loading={loading}
      storeCartId={storeCartId}
    />
  )
}
export default StoreToCart
