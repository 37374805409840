import React from 'react'
import Helmet from 'react-helmet'
import StoreToCartInput from '@components/store-to-cart-input'
import Layout from '../components/layout'

const StoreToCartInputPage = props => (
  <Layout {...props} cartPage>
    <Helmet title="Store Cart - Rooms To Go" />
    <StoreToCartInput />
  </Layout>
)

export default StoreToCartInputPage
